import * as tslib_1 from "tslib";
import { Events, NavController, AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from '../user/user.service';
import * as firebase from 'firebase';
import { map, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConfigService } from '../config/config.service';
import { CartService } from '../cart/cart.service';
import { LogglyLoggerService } from '../loggly-logger/loggly-logger.service';
import { LabelService } from '../label/label.service';
import { AngularFireStorage } from '@angular/fire/storage';
import * as i0 from "@angular/core";
import * as i1 from "@ionic/angular";
import * as i2 from "@ionic/storage";
import * as i3 from "@angular/fire/firestore";
import * as i4 from "../user/user.service";
import * as i5 from "@angular/router";
import * as i6 from "../config/config.service";
import * as i7 from "../loggly-logger/loggly-logger.service";
import * as i8 from "../label/label.service";
import * as i9 from "@angular/fire/storage";
import * as i10 from "../cart/cart.service";
export class OrderService {
    constructor(events, storage, afs, userService, navController, router, modalController, loadingController, alertController, configService, logglyService, labelService, angularFireStorage, cartService, fbStorage) {
        this.events = events;
        this.storage = storage;
        this.afs = afs;
        this.userService = userService;
        this.navController = navController;
        this.router = router;
        this.modalController = modalController;
        this.loadingController = loadingController;
        this.alertController = alertController;
        this.configService = configService;
        this.logglyService = logglyService;
        this.labelService = labelService;
        this.angularFireStorage = angularFireStorage;
        this.cartService = cartService;
        this.fbStorage = fbStorage;
    }
    initializeSubscriptions() {
        this.events.subscribe('order:payWithRazorPay', (order, razorpayId, method) => {
            this.payWithRazorPay(order, razorpayId, method);
        });
        this.events.subscribe('order:payWithPaytm', (order) => {
            this.payWithPaytm(order);
        });
        this.events.subscribe('order:sendPaymentRequest', (orderId, userId) => {
            this.sendPaymentRequest(orderId, userId);
        });
        this.events.subscribe('order:payWithCash', (order) => {
            this.payWithCash(order);
        });
        this.events.subscribe('order:updatePaymentComplete', (orderId) => {
            this.updatePaymentComplete(orderId);
        });
        this.events.subscribe('order:completePaymentWithWallet', (order) => {
            this.completePaymentWithWallet(order);
        });
        //auto confirm...
        this.events.subscribe('order:ac_payWithRazorPay', (order, razorpayId, method) => {
            this.ac_payWithRazorPay(order, razorpayId, method);
        });
        this.events.subscribe('order:ac_payWithPaytm', (order) => {
            this.ac_payWithPaytm(order);
        });
        this.events.subscribe('order:ac_payWithCash', (order) => {
            this.ac_payWithCash(order);
        });
        this.events.subscribe('order:ac_completePaymentWithWallet', (order) => {
            this.ac_completePaymentWithWallet(order);
        });
        this.events.subscribe('order:ac_paytmWebInitiateTxn', (order) => {
            this.ac_paytmWebInitiateTxn(order);
        });
        this.events.subscribe('order:ac_completeUPIManualPayment', (order, paymentImg) => {
            this.ac_completeUPIManualPayment(order, paymentImg);
        });
        this.events.subscribe('order:ac_completeCustomOptionPayment', (order, response) => {
            this.ac_completeCustomOptionPayment(order, response);
        });
        this.events.subscribe('order:placeOrder', (products, listOfCommentImages, address, paymentObj) => {
            this.placeOrder(products, listOfCommentImages, address, paymentObj);
        });
        this.events.subscribe('order:autoConfirmPlaceOrder', (products, listOfCommentImages, address, paymentObj, isCodAvailableForCoupon) => {
            this.autoConfirmPlaceOrder(products, listOfCommentImages, address, paymentObj, isCodAvailableForCoupon);
        });
    }
    payWithRazorPay(order, razorpayId, method) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
                yield this.presentLoading();
                let createOrderInRazorpay = firebase.functions().httpsCallable('payments-razorpay_createOrder');
                const createOrderRes = yield createOrderInRazorpay({ amount: Math.round(payableAmnt * 100), orderDocId: order.id });
                const razorpayOrderId = createOrderRes.data && createOrderRes.data.orderId ? createOrderRes.data.orderId : '';
                console.log('razorpayOrderId', razorpayOrderId);
                if (razorpayOrderId) {
                    const storeInfo = yield this.storage.get('storeInfo');
                    const options = {
                        order_id: razorpayOrderId,
                        description: this.configService.environment.razorpay.description,
                        currency: this.configService.environment.razorpay.currency,
                        key: razorpayId,
                        amount: Math.round(payableAmnt * 100),
                        name: storeInfo && storeInfo.storeName ? storeInfo.storeName : '',
                        image: this.configService.environment.razorpay.image,
                        prefill: {
                            method: method,
                            contact: this.userService.getPhoneNo() || '',
                            name: this.userService.getUserName() || '',
                            email: this.userService.getUserEmail() || 'xyz@gmail.com',
                        },
                        theme: this.configService.environment.razorpay.theme,
                        modal: {
                            ondismiss: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                this.loading.dismiss();
                                this.presentFailureAlert('razorpay');
                            })
                        },
                        handler: (handlerResponse) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            console.log('handlerResponse', handlerResponse);
                            let verifySignature = firebase.functions().httpsCallable('payments-razorpay_verifySignature');
                            const apiBody = {
                                razorpay_payment_id: handlerResponse.razorpay_payment_id,
                                razorpay_order_id: handlerResponse.razorpay_order_id,
                                razorpay_signature: handlerResponse.razorpay_signature,
                            };
                            const txnRes = { paymentId: apiBody.razorpay_payment_id };
                            yield this.updateRazorpayPaymentId(order.id, txnRes);
                            const verifySignatureRes = yield verifySignature(apiBody);
                            console.log('verifySignatureRes', verifySignatureRes);
                            if (verifySignatureRes.data.signatureIsValid) {
                                const paymentDetails = {
                                    order: order,
                                    mode: 'razorpay',
                                    txnRes,
                                    amount: options.amount
                                };
                                let saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                                saveOrderPaymentDetails(paymentDetails).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                    if (res.data.status && res.data.status === 'success') {
                                        this.loading.dismiss();
                                        const paymentChatMsg = {
                                            author: 'user',
                                            createdAt: new Date(),
                                            isRead: true,
                                            orderId: order.orderId,
                                            published: true,
                                            status: 'PaymentMsg',
                                            type: 'order',
                                            paymentMode: 'razorpay'
                                        };
                                        yield this.paymentChatMsgs(paymentChatMsg, order);
                                        this.presentAlert('Payment is successful!');
                                    }
                                }));
                            }
                            else {
                                this.loading.dismiss();
                                this.presentFailureAlert('razorpay');
                            }
                        }),
                    };
                    var razorpay = new Razorpay(options);
                    razorpay.open();
                    razorpay.on('payment.failed', (response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        console.log('failure response', response);
                        this.loading.dismiss();
                        const paymentId = response.error && response.error.metadata && response.error.metadata.payment_id ? response.error.metadata.payment_id : '';
                        if (paymentId) {
                            const txnRes = { paymentId };
                            yield this.updateRazorpayPaymentId(order.id, txnRes);
                        }
                        this.presentFailureAlert('razorpay');
                    }));
                }
                else {
                    this.loading.dismiss();
                    this.presentFailureAlert('razorpay');
                }
            }
            catch (error) {
                console.dir(error);
                this.events.publish('order:paymentFailure');
            }
        });
    }
    payWithPaytm(order) {
        const mobileNo = this.userService.getPhoneNo().slice(3);
        //// console.log(typeof mobileNo, mobileNo);
        const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
        let getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
        getCheckSum({
            orderId: order.orderId.toString(),
            customerId: order.userId,
            phoneNo: mobileNo,
            txnAmount: payableAmnt.toString(),
        }).then((result) => {
            //// console.log('checksum:', result.data.checksum);
            const paytmParams = {
                MID: result.data.mid,
                ORDER_ID: order.orderId.toString(),
                CUST_ID: order.userId,
                CHANNEL_ID: this.configService.environment.paytm.CHANNEL_ID,
                TXN_AMOUNT: payableAmnt.toString(),
                WEBSITE: this.configService.environment.paytm.WEBSITE,
                CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + order.orderId,
                INDUSTRY_TYPE_ID: this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                MOBILE_NO: mobileNo,
                CHECKSUMHASH: result.data.checksum,
                ENVIRONMENT: this.configService.environment.paytm.ENVIRONMENT
            };
            let successCallback = (response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (response.STATUS == "TXN_SUCCESS") {
                    yield this.presentLoading();
                    const paymentDetails = {
                        order: order,
                        mode: 'paytm',
                        txnRes: response,
                        amount: paytmParams.TXN_AMOUNT
                    };
                    let saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                    saveOrderPaymentDetails(paymentDetails).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        //// console.log('res of pd', res.data);
                        if (res.data.status && res.data.status === 'success') {
                            this.loading.dismiss();
                            const paymentChatMsg = {
                                author: 'user',
                                createdAt: new Date(),
                                isRead: true,
                                orderId: order.orderId,
                                published: true,
                                status: 'PaymentMsg',
                                type: 'order',
                                paymentMode: 'paytm'
                            };
                            yield this.paymentChatMsgs(paymentChatMsg, order);
                            this.presentAlert('Payment is successful!');
                        }
                    }));
                }
                else {
                    this.presentFailureAlert();
                }
            });
            let failureCallback = (error) => {
                this.presentFailureAlert();
            };
            paytm.startPayment(paytmParams, successCallback, failureCallback);
        }).catch(function (error) {
            var code = error.code;
            var message = error.message;
            var details = error.details;
            //// console.log("Error", code, message, details);
            this.presentFailureAlert();
        });
    }
    sendPaymentRequest(orderId, userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const paymentReqMsg = {
                    author: 'admin',
                    createdAt: new Date(),
                    isRead: true,
                    orderId: orderId,
                    published: true,
                    status: 'PaymentRequest',
                    type: 'order'
                };
                this.events.publish('chat:sendMsg', paymentReqMsg, userId);
                this.events.publish('order:sendPaymentRequestSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    payWithCash(order) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let paymentWithCash = firebase.functions().httpsCallable('payments-paymentWithCash');
                paymentWithCash(order).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (res.data.status && res.data.status === 'success') {
                        yield this.paymentChatMsgs('cash', order);
                        this.events.publish('order:modeSetToCashSuccess');
                    }
                    else {
                        this.presentFailureAlert();
                    }
                }));
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    updatePaymentComplete(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', orderId));
                const orderData = yield orderRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                    const data = a.payload.doc.data();
                    const id = a.payload.doc.id;
                    return Object.assign({ id }, data);
                }))).pipe(first()).toPromise();
                yield this.afs.collection('orders').doc(orderData[0].id).update({
                    payment: {
                        completed: true,
                        mode: 'cash'
                    }
                });
                this.events.publish('order:updatePaymentCompleteSuccess');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    completePaymentWithWallet(order) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let paymentWithWallet = firebase.functions().httpsCallable('payments-completePaymentWithWallet');
                paymentWithWallet(order).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (res.data.status && res.data.status === 'success') {
                        this.events.publish('order:completePaymentWithWalletSuccess');
                        const paymentChatMsg = {
                            author: 'user',
                            createdAt: new Date(),
                            isRead: true,
                            orderId: order.orderId,
                            published: true,
                            status: 'PaymentMsg',
                            type: 'order',
                            paymentMode: 'wallet'
                        };
                        yield this.paymentChatMsgs(paymentChatMsg, order);
                    }
                    else {
                        this.presentFailureAlert();
                    }
                }));
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    paymentChatMsgs(chatObj, order) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const chatRef = this.afs.collection('chats').doc(order.userId).collection('messages', ref => ref
                .where('orderId', '==', order.orderId)
                .where('status', 'in', ['Confirmed', 'PaymentRequest']));
            const chatSnap = yield chatRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                const data = a.payload.doc.data();
                const id = a.payload.doc.id;
                return Object.assign({ id }, data);
            }))).pipe(first()).toPromise();
            for (let index = 0; index < chatSnap.length; index++) {
                //// console.log('msg id of do payment', chatSnap[index].id);
                yield this.afs.collection('chats').doc(order.userId).collection('messages').doc(chatSnap[index].id).delete();
            }
            this.events.publish('chat:sendMsg', chatObj, order.userId);
        });
    }
    //Auto confrim order functions
    ac_payWithRazorPay(order, razorpayId, method) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.presentLoading();
                order['createdAt'] = new Date();
                const orderId = this.afs.collection('orders').ref.doc().id;
                const listOfCommentImages = Object.assign({}, order['listOfCommentImages']);
                delete order['listOfCommentImages'];
                if (order.uploadedDoc.uploads.length !== 0) {
                    const uploads = yield this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                    order.uploadedDoc['uploads'] = uploads;
                }
                if (!order.userName) {
                    order.userName = order.address && order.address.name ? order.address.name : '';
                }
                yield this.afs.collection('orders').doc(orderId).set(order);
                order['orderDocId'] = orderId;
                this.addCommentImgs(listOfCommentImages, orderId);
                this.clearProductsInCartIfAny(order.userId);
                const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
                //create order in razorpay
                let createOrderInRazorpay = firebase.functions().httpsCallable('payments-razorpay_createOrder');
                const createOrderRes = yield createOrderInRazorpay({ amount: Math.round(payableAmnt * 100), orderDocId: orderId });
                const razorpayOrderId = createOrderRes.data && createOrderRes.data.orderId ? createOrderRes.data.orderId : '';
                console.log('razorpayOrderId', razorpayOrderId);
                if (razorpayOrderId) {
                    const storeInfo = yield this.storage.get('storeInfo');
                    const options = {
                        order_id: razorpayOrderId,
                        description: this.configService.environment.razorpay.description,
                        currency: this.configService.environment.razorpay.currency,
                        key: razorpayId,
                        amount: Math.round(payableAmnt * 100),
                        name: storeInfo && storeInfo.storeName ? storeInfo.storeName : '',
                        image: this.configService.environment.razorpay.image,
                        prefill: {
                            method: method,
                            contact: this.userService.getPhoneNo() || '',
                            name: this.userService.getUserName() || '',
                            email: this.userService.getUserEmail() || 'xyz@gmail.com',
                        },
                        theme: this.configService.environment.razorpay.theme,
                        modal: {
                            ondismiss: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                this.loading.dismiss();
                                // await this.paymentFailedUpdate(order.orderDocId);
                                this.presentFailureAlert('razorpay');
                            })
                        },
                        handler: (handlerResponse) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            console.log('handlerResponse', handlerResponse);
                            let verifySignature = firebase.functions().httpsCallable('payments-razorpay_verifySignature');
                            const apiBody = {
                                razorpay_payment_id: handlerResponse.razorpay_payment_id,
                                razorpay_order_id: handlerResponse.razorpay_order_id,
                                razorpay_signature: handlerResponse.razorpay_signature,
                            };
                            const txnRes = { paymentId: apiBody.razorpay_payment_id };
                            yield this.updateRazorpayPaymentId(order.orderDocId, txnRes);
                            const verifySignatureRes = yield verifySignature(apiBody);
                            console.log('verifySignatureRes', verifySignatureRes);
                            if (verifySignatureRes.data.signatureIsValid) {
                                const paymentDetails = {
                                    order: order,
                                    mode: 'razorpay',
                                    txnRes,
                                    amount: options.amount
                                };
                                let ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                                ac_saveOrderPaymentDetails(paymentDetails).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                                    if (res.data.status && res.data.status === 'success') {
                                        this.loading.dismiss();
                                        this.presentAlert('Order has been placed successfully!');
                                    }
                                    else {
                                        // await this.paymentFailedUpdate(order.orderDocId);
                                        this.loading.dismiss();
                                        this.presentFailureAlert('razorpay');
                                    }
                                }));
                            }
                            else {
                                this.loading.dismiss();
                                // await this.paymentFailedUpdate(order.orderDocId);
                                this.presentFailureAlert('razorpay');
                            }
                        }),
                    };
                    var razorpay = new Razorpay(options);
                    razorpay.open();
                    razorpay.on('payment.failed', (response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        console.log('inside payment.failed');
                        if (!response.error && response.error.description && response.error.description.includes('undefined')) {
                            console.log('failure response', response);
                            this.loading.dismiss();
                            const paymentId = response.error && response.error.metadata && response.error.metadata.payment_id ? response.error.metadata.payment_id : '';
                            if (paymentId) {
                                const txnRes = { paymentId };
                                yield this.updateRazorpayPaymentId(orderId, txnRes);
                            }
                            // await this.paymentFailedUpdate(order.orderDocId);
                            this.presentFailureAlert('razorpay');
                        }
                    }));
                }
                else {
                    this.loading.dismiss();
                    // await this.paymentFailedUpdate(order.orderDocId);
                    this.presentFailureAlert('razorpay');
                }
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    updateRazorpayPaymentId(orderId, txnRes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.afs.collection('orders').doc(orderId).update({
                'payment.details': txnRes,
                'payment.mode': 'razorpay'
            });
        });
    }
    paymentFailedUpdate(orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.afs.collection('orders').doc(orderId).update({
                    'payment.completed': false,
                    'payment.status': 'failed',
                    walletAmount: 0,
                    cashbackAmount: 0,
                    extraChargeOnPayment: {
                        charge: 0
                    },
                    status: 'Pending'
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    ac_payWithPaytm(order) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.presentLoading();
            const mobileNo = this.userService.getPhoneNo().slice(3);
            const orderId = this.afs.collection('orders').ref.doc().id;
            order['createdAt'] = new Date();
            const listOfCommentImages = Object.assign({}, order['listOfCommentImages']);
            delete order['listOfCommentImages'];
            if (order.uploadedDoc.uploads.length !== 0) {
                const uploads = yield this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                order.uploadedDoc['uploads'] = uploads;
            }
            yield this.afs.collection('orders').doc(orderId).set(order);
            order['orderDocId'] = orderId;
            const orderRefId = order.orderDocId;
            this.addCommentImgs(listOfCommentImages, orderId);
            const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
            let getCheckSum = firebase.functions().httpsCallable('payments-getCheckSumApi');
            getCheckSum({
                orderId: orderRefId.toString(),
                customerId: order.userId,
                phoneNo: mobileNo,
                txnAmount: payableAmnt.toString(),
            }).then((result) => {
                const paytmParams = {
                    MID: result.data.mid,
                    ORDER_ID: orderRefId.toString(),
                    CUST_ID: order.userId,
                    CHANNEL_ID: this.configService.environment.paytm.CHANNEL_ID,
                    TXN_AMOUNT: payableAmnt.toString(),
                    WEBSITE: this.configService.environment.paytm.WEBSITE,
                    CALLBACK_URL: "https://securegw-stage.paytm.in/theia/paytmCallback?ORDER_ID=" + orderRefId.toString(),
                    INDUSTRY_TYPE_ID: this.configService.environment.paytm.INDUSTRY_TYPE_ID,
                    MOBILE_NO: mobileNo,
                    CHECKSUMHASH: result.data.checksum,
                    ENVIRONMENT: this.configService.environment.paytm.ENVIRONMENT
                };
                let successCallback = (response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (response.STATUS == "TXN_SUCCESS") {
                        const paymentDetails = {
                            order: order,
                            mode: 'paytm',
                            txnRes: response,
                            amount: paytmParams.TXN_AMOUNT
                        };
                        let ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                        ac_saveOrderPaymentDetails(paymentDetails).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            if (res.data.status && res.data.status === 'success') {
                                this.loading.dismiss();
                                this.presentAlert('Order has been placed successfully!');
                                this.clearProductsInCartIfAny(order.userId);
                            }
                            else {
                                yield this.paymentFailedUpdate(order.orderDocId);
                                this.loading.dismiss();
                                this.presentFailureAlert();
                            }
                        }));
                    }
                    else {
                        yield this.paymentFailedUpdate(order.orderDocId);
                        this.loading.dismiss();
                        this.presentFailureAlert();
                    }
                });
                let failureCallback = (error) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    yield this.paymentFailedUpdate(order.orderDocId);
                    this.loading.dismiss();
                    this.presentFailureAlert();
                });
                paytm.startPayment(paytmParams, successCallback, failureCallback);
            }).catch(function (error) {
                var code = error.code;
                var message = error.message;
                var details = error.details;
                //// console.log("Error", code, message, details);
                this.presentFailureAlert();
            });
        });
    }
    ac_completePaymentWithWallet(order) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const orderId = this.afs.collection('orders').ref.doc().id;
                order['createdAt'] = new Date();
                const listOfCommentImages = Object.assign({}, order['listOfCommentImages']);
                delete order['listOfCommentImages'];
                if (order.uploadedDoc.uploads.length !== 0) {
                    const uploads = yield this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                    order.uploadedDoc['uploads'] = uploads;
                }
                yield this.afs.collection('orders').doc(orderId).set(order);
                order['orderDocId'] = orderId;
                this.addCommentImgs(listOfCommentImages, orderId);
                let orderPaymentWithWallet = firebase.functions().httpsCallable('wallet-orderPaymentWithWallet');
                orderPaymentWithWallet(order).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (res.data.status && res.data.status === 'success') {
                        this.events.publish('order:ac_completePaymentWithWalletSuccess');
                        this.clearProductsInCartIfAny(order.userId);
                    }
                    else {
                        yield this.paymentFailedUpdate(order.orderDocId);
                        this.presentFailureAlert();
                    }
                }));
            }
            catch (error) {
                console.dir(error);
                this.presentFailureAlert();
            }
        });
    }
    clearProductsInCartIfAny(userId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.storage.get('buyNowOrder').then((val) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (!val) {
                    const cartRef = this.afs.collection('users').doc(userId).collection('cart');
                    const cartData = yield cartRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return Object.assign({ id }, data);
                    }))).pipe(first()).toPromise();
                    for (let i = 0; i < cartData.length; i++) {
                        yield this.cartService.deleteCartProduct(cartData[i].id);
                    }
                    this.storage.set('cart', []);
                }
            }));
        });
    }
    ac_payWithCash(order) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                order['payment'] = {
                    completed: false,
                    mode: 'cash',
                    details: {
                        amount: order.totalAmountToPaid - order.walletAmount - order.cashbackAmount
                    }
                };
                let listOfCommentImages = order['listOfCommentImages'];
                let ac_paymentWithCash = firebase.functions().httpsCallable('payments-ac_paymentWithCash');
                ac_paymentWithCash(order).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (res.data.status && res.data.status === 'success') {
                        const orderId = res.data.orderDocId;
                        if (order.uploadedDoc.uploads.length !== 0) {
                            const uploads = yield this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                            yield this.afs.collection('orders').doc(orderId).update({
                                "uploadedDoc.uploads": uploads
                            });
                        }
                        this.addCommentImgs(listOfCommentImages, orderId);
                        this.events.publish('order:ac_modeSetToCashSuccess');
                        this.clearProductsInCartIfAny(order.userId);
                    }
                    else {
                        this.presentFailureAlert();
                    }
                }));
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    // paytm web...
    ac_paytmWebInitiateTxn(order) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const orderRefId = this.afs.collection('orders').ref.doc().id;
                const initiateParams = {
                    orderId: orderRefId.toString(),
                    customerId: order.userId,
                    txnAmount: (order.totalAmountToPaid - order.walletAmount - order.cashbackAmount).toString()
                };
                let initiateTxn = firebase.functions().httpsCallable('payments-initiateTxnApi');
                initiateTxn(initiateParams).then(res => {
                    //// console.log('token...', res.data);
                    this.events.publish('order:initiateTxnSuccess', res.data, orderRefId.toString());
                });
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    presentLoading() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('loading');
            this.loading = yield this.loadingController.create({
                message: 'Please Wait...',
            });
            yield this.loading.present();
        });
    }
    ac_payWithUPIManual(order) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.presentLoading();
                const orderId = this.afs.collection('orders').ref.doc().id;
                order['createdAt'] = new Date();
                order['status'] = 'Pending';
                const listOfCommentImages = Object.assign({}, order['listOfCommentImages']);
                delete order['listOfCommentImages'];
                if (order.uploadedDoc.uploads.length !== 0) {
                    const uploads = yield this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                    order.uploadedDoc['uploads'] = uploads;
                }
                yield this.afs.collection('orders').doc(orderId).set(order);
                order['orderDocId'] = orderId;
                this.addCommentImgs(listOfCommentImages, orderId);
                this.clearProductsInCartIfAny(order.userId);
                this.loading.dismiss();
                return ({ order });
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'order-service:ac_payWithUPIManual';
                this.logglyService.log(error);
            }
        });
    }
    ac_completeUPIManualPayment(order, paymentImg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.presentLoading();
                let orderId = '';
                if (order.hasOwnProperty('orderDocId')) {
                    orderId = order.orderDocId;
                }
                else {
                    const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', order.orderId));
                    const orderData = yield orderRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return Object.assign({ id }, data);
                    }))).pipe(first()).toPromise();
                    orderId = orderData[0].id;
                }
                const imgRef = this.fbStorage.ref(`upiManualPayment/${orderId}/image/` + new Date().getTime().toString() + '.png');
                yield imgRef.putString(paymentImg, 'data_url');
                const downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                const payment = {
                    completed: true,
                    mode: 'upiManual',
                    screenshot: downloadURL,
                    status: 'completed'
                };
                yield this.afs.collection('orders').doc(orderId).update({
                    payment: payment,
                    status: 'Confirmed',
                    scheduledDate: order.scheduledDate ? new Date(order.scheduledDate) : '',
                    scheduledTime: order.scheduledTime
                });
                this.loading.dismiss();
                this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                if (order.walletAmount > 0 || order.cashbackAmount > 0) {
                    const walletObj = {
                        walletAmount: order.walletAmount,
                        cashbackAmount: order.cashbackAmount,
                        orderId: orderId,
                        userId: order.userId
                    };
                    let updateUserWalletAmount = firebase.functions().httpsCallable('payments-updateUserWalletAmount');
                    updateUserWalletAmount(walletObj).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        console.log(res);
                    }));
                }
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'order-service:ac_completeUPIManualPayment';
                this.logglyService.log(error);
            }
        });
    }
    // async ac_completeUPIManualPayment(order, paymentImg) {
    //     try {
    //         await this.presentLoading();
    //         order['createdAt'] = new Date();
    //         order['status'] = 'Confirmed';
    //         order['scheduledDate'] = order.scheduledDate ? new Date(order.scheduledDate) : '';
    //         order['scheduledTime'] = order.scheduledTime;
    //         let orderId = '';
    //         if (!order.orderId) {
    //             orderId = this.afs.collection('orders').ref.doc().id;
    //             if (order.uploadedDoc.uploads.length !== 0) {
    //                 const uploads = await this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
    //                 order.uploadedDoc['uploads'] = uploads;
    //             }
    //         } else {
    //             const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', order.orderId));
    //             const orderData: any = await orderRef.snapshotChanges().pipe(
    //                 map(actions => actions.map(a => {
    //                     const data = a.payload.doc.data();
    //                     const id = a.payload.doc.id;
    //                     return { id, ...data as {} };
    //                 }))
    //             ).pipe(first()).toPromise();
    //             orderId = orderData[0].id;
    //         }
    //         const imgRef: any = this.angularFireStorage.ref(`upiManualPayment/${orderId}/image/` + new Date().getTime().toString() + '.png');
    //         await imgRef.putString(paymentImg, 'data_url');
    //         const downloadURL = await imgRef.getDownloadURL().pipe(first()).toPromise();
    //         order['payment'] = {
    //             completed: true,
    //             mode: 'upiManual',
    //             screenshot: downloadURL,
    //             status: 'completed'
    //         }
    //         if (!order.orderId) {
    //             const listOfCommentImages = { ...order['listOfCommentImages'] };
    //             delete order['listOfCommentImages'];
    //             await this.afs.collection('orders').doc(orderId).set(order);
    //             this.addCommentImgs(listOfCommentImages, orderId);
    //             this.clearProductsInCartIfAny(order.userId);
    //         } else {
    //             await this.afs.collection('orders').doc(orderId).update(order);
    //         }
    //         this.loading.dismiss();
    //         this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
    //         if (order.walletAmount > 0 || order.cashbackAmount > 0) {
    //             const walletObj = {
    //                 walletAmount: order.walletAmount,
    //                 cashbackAmount: order.cashbackAmount,
    //                 orderId: orderId,
    //                 userId: order.userId
    //             }
    //             let updateUserWalletAmount = firebase.functions().httpsCallable('payments-updateUserWalletAmount');
    //             updateUserWalletAmount(walletObj).then(async (res) => {
    //                 console.log(res);
    //             });
    //         }
    //     } catch (error) {
    //         console.dir(error);
    //         error['location'] = 'order-service:ac_completeUPIManualPayment';
    //         this.logglyService.log(error);
    //     }
    // }
    presentAlert(msg) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const alert = yield this.alertController.create({
                message: msg,
                backdropDismiss: false,
                buttons: [{
                        text: 'OK',
                        handler: () => {
                            this.modalController.dismiss();
                            this.navController.navigateRoot(['order-successful']);
                        }
                    }]
            });
            yield alert.present();
        });
    }
    presentFailureAlert(mode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.loading) {
                this.loading.dismiss();
            }
            const alert = yield this.alertController.create({
                message: mode === 'razorpay' ? 'We are verifying your payment status. It may take upto 15 minutes. Please check order details from my orders for further information'
                    : 'Payment is failed! Any amount debited will be refunded in 4 - 5 working days.',
                backdropDismiss: false,
                buttons: [{
                        text: 'Ok',
                        handler: () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            const isExternalOrder = yield this.storage.get('isExternalOrder');
                            if (isExternalOrder) {
                                this.navController.navigateRoot(['order-failure']);
                            }
                            else {
                                this.navController.navigateRoot(['user-order-history']);
                            }
                        })
                    }]
            });
            yield alert.present();
        });
    }
    addCommentImgs(listOfCommentImages, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (Object.keys(listOfCommentImages).length !== 0) {
                for (let pid of Object.keys(listOfCommentImages)) {
                    const imgs = listOfCommentImages[pid];
                    for (const img of imgs) {
                        const imgRef = this.fbStorage.ref(`ordersCommentImgs/${orderId}/images/${pid}/` + new Date().getTime() + '.png');
                        yield imgRef.putString(img, 'data_url');
                    }
                }
            }
        });
    }
    placeOrder(products, listOfCommentImages, address, paymentObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const finalProducts = products.map((_a) => {
                    var { cartUpdateTimer, priceSlabs, priceStatus, stopWhenNoQty, templateId, totalQty } = _a, keepAttrs = tslib_1.__rest(_a, ["cartUpdateTimer", "priceSlabs", "priceStatus", "stopWhenNoQty", "templateId", "totalQty"]);
                    return keepAttrs;
                });
                paymentObj['products'] = finalProducts;
                paymentObj['address'] = address;
                let orderObj = yield this.getOrderObject(paymentObj);
                const orderId = this.afs.collection('orders').ref.doc().id;
                if (orderObj.uploadedDoc.uploads.length !== 0) {
                    const uploads = yield this.getUploadedDocUrls(orderId, orderObj.uploadedDoc.uploads);
                    orderObj.uploadedDoc['uploads'] = uploads;
                }
                console.log('orderObj:', orderObj);
                if (!orderObj.userName) {
                    orderObj.userName = orderObj.address && orderObj.address.name ? orderObj.address.name : '';
                }
                yield this.afs.collection('orders').doc(orderId).set(orderObj);
                if (address.lat) {
                    yield this.afs.collection('orders').doc(orderId).update({
                        deliveryLatLng: {
                            lat: address.lat,
                            lng: address.lng
                        }
                    });
                }
                this.addCommentImgs(listOfCommentImages, orderId);
                this.clearProductsInCartIfAny(orderObj.userId);
                this.events.publish('order:orderSuccessfullyPlaced');
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    autoConfirmPlaceOrder(products, listOfCommentImages, address, paymentObj, isCodAvailableForCoupon) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const finalProducts = products.map((_a) => {
                    var { cartUpdateTimer, priceSlabs, priceStatus, stopWhenNoQty, templateId, totalQty } = _a, keepAttrs = tslib_1.__rest(_a, ["cartUpdateTimer", "priceSlabs", "priceStatus", "stopWhenNoQty", "templateId", "totalQty"]);
                    return keepAttrs;
                });
                paymentObj['products'] = finalProducts;
                paymentObj['address'] = address;
                let orderObj = yield this.getOrderObject(paymentObj);
                orderObj['status'] = 'Pending';
                orderObj['payment']['status'] = 'pending';
                if (address.lat) {
                    orderObj['deliveryLatLng'] = {
                        lat: address.lat,
                        lng: address.lng
                    };
                }
                orderObj['listOfCommentImages'] = listOfCommentImages;
                orderObj['isCodAvailableForCoupon'] = isCodAvailableForCoupon;
                this.events.publish('order:autoConfirmPlaceOrderSuccess', orderObj);
            }
            catch (error) {
                console.dir(error);
            }
        });
    }
    getOrderObject(paymentObj) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let region = yield this.storage.get('region');
            let userId = yield this.storage.get('uid');
            return Object.assign({}, paymentObj, { orderId: null, status: 'Pending', createdAt: new Date(), payment: {
                    completed: false,
                    mode: null,
                    details: null
                }, userId: userId, msgId: this.afs.collection('chats').doc(userId).collection('messages').ref.doc().id, userName: this.userService.getUserName(), discount: 0, region: region && region.name ? region.name : '', vendorId: region && region.vendorId ? region.vendorId : '' });
        });
    }
    getUploadedDocUrls(orderId, uploads) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                for (const img of uploads) {
                    const imgRef = this.angularFireStorage.ref(`orders/${orderId}/uploadedDoc/` + new Date().getTime() + '.png');
                    yield imgRef.putString(img.url, 'data_url');
                    img.url = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                }
                resolve(uploads);
            }));
        });
    }
    saveResaleData(resale, orderId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    yield this.afs.collection('orders').doc(orderId).update({ resale });
                    resolve(true);
                }
                catch (error) {
                    console.dir(error);
                    error['location'] = 'user-service:saveResaleData';
                    this.logglyService.log(error);
                    resolve(false);
                }
            }));
        });
    }
    ac_completeCustomOptionPayment(order, userResponse) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.presentLoading();
                order['createdAt'] = new Date();
                order['status'] = 'Confirmed';
                order['scheduledDate'] = order.scheduledDate ? new Date(order.scheduledDate) : '';
                order['scheduledTime'] = order.scheduledTime;
                let orderId = '';
                if (!order.orderId) {
                    orderId = this.afs.collection('orders').ref.doc().id;
                    if (order.uploadedDoc.uploads.length !== 0) {
                        const uploads = yield this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                        order.uploadedDoc['uploads'] = uploads;
                    }
                }
                else {
                    const orderRef = this.afs.collection('orders', ref => ref.where('orderId', '==', order.orderId));
                    const orderData = yield orderRef.snapshotChanges().pipe(map(actions => actions.map(a => {
                        const data = a.payload.doc.data();
                        const id = a.payload.doc.id;
                        return Object.assign({ id }, data);
                    }))).pipe(first()).toPromise();
                    orderId = orderData[0].id;
                }
                let downloadURL = '';
                if (userResponse.image) {
                    const imgRef = this.fbStorage.ref(`customPayment/${orderId}/image/` + new Date().getTime().toString() + '.png');
                    yield imgRef.putString(userResponse.image, 'data_url');
                    downloadURL = yield imgRef.getDownloadURL().pipe(first()).toPromise();
                }
                order['payment'] = {
                    completed: true,
                    mode: 'custom',
                    optionName: userResponse.optionName,
                    screenshot: downloadURL,
                    textDetails: userResponse.textDetails,
                    status: 'completed'
                };
                if (!order.orderId) {
                    const listOfCommentImages = Object.assign({}, order['listOfCommentImages']);
                    delete order['listOfCommentImages'];
                    yield this.afs.collection('orders').doc(orderId).set(order);
                    this.addCommentImgs(listOfCommentImages, orderId);
                    this.clearProductsInCartIfAny(order.userId);
                }
                else {
                    yield this.afs.collection('orders').doc(orderId).update(order);
                }
                this.loading.dismiss();
                this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                if (order.walletAmount > 0 || order.cashbackAmount > 0) {
                    const walletObj = {
                        walletAmount: order.walletAmount,
                        cashbackAmount: order.cashbackAmount,
                        orderId: orderId,
                        userId: order.userId
                    };
                    let updateUserWalletAmount = firebase.functions().httpsCallable('payments-updateUserWalletAmount');
                    updateUserWalletAmount(walletObj).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        console.log(res);
                    }));
                }
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'order-service:ac_completeCustomOptionPayment';
                this.logglyService.log(error);
            }
        });
    }
    ac_payWithStripe(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const order = data.order;
                yield this.presentLoading();
                const orderId = this.afs.collection('orders').ref.doc().id;
                order['createdAt'] = new Date();
                const listOfCommentImages = Object.assign({}, order['listOfCommentImages']);
                delete order['listOfCommentImages'];
                if (order.uploadedDoc.uploads.length !== 0) {
                    const uploads = yield this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                    order.uploadedDoc['uploads'] = uploads;
                }
                yield this.afs.collection('orders').doc(orderId).set(order);
                order['orderDocId'] = orderId;
                this.addCommentImgs(listOfCommentImages, orderId);
                this.clearProductsInCartIfAny(order.userId);
                const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
                const stripeData = {
                    amount: payableAmnt * 100,
                    currency: this.configService.environment.currencyCode,
                    token: data.token
                };
                let payWithStripe = firebase.functions().httpsCallable('payments-payWithStripe');
                payWithStripe(stripeData).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    console.log('res of pay with stripe', res);
                    if (res.data.status && res.data.status === 'success') {
                        const paymentDetails = {
                            order: order,
                            mode: 'stripe',
                            txnRes: res.data.txnRes,
                            amount: payableAmnt
                        };
                        let ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                        ac_saveOrderPaymentDetails(paymentDetails).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            if (res.data.status && res.data.status === 'success') {
                                this.loading.dismiss();
                                this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                            }
                            else {
                                yield this.paymentFailedUpdate(order.orderDocId);
                                this.loading.dismiss();
                                this.presentFailureAlert();
                            }
                        }));
                    }
                    else {
                        yield this.paymentFailedUpdate(order.orderDocId);
                        this.loading.dismiss();
                        this.presentFailureAlert();
                    }
                }));
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'order-service:ac_payWithRazorPay';
                this.logglyService.log(error);
            }
        });
    }
    payWithStripe(data) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.presentLoading();
                const order = data.order;
                const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
                const stripeData = {
                    amount: payableAmnt * 100,
                    currency: this.configService.environment.currencyCode,
                    token: data.token
                };
                let payWithStripe = firebase.functions().httpsCallable('payments-payWithStripe');
                payWithStripe(stripeData).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    console.log('res of pay with stripe', res);
                    if (res.data.status && res.data.status === 'success') {
                        const paymentDetails = {
                            order: order,
                            mode: 'stripe',
                            txnRes: res.data.txnRes,
                            amount: payableAmnt
                        };
                        let saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                        saveOrderPaymentDetails(paymentDetails).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                            if (res.data.status && res.data.status === 'success') {
                                const paymentChatMsg = {
                                    author: 'user',
                                    createdAt: new Date(),
                                    isRead: true,
                                    orderId: order.orderId,
                                    published: true,
                                    status: 'PaymentMsg',
                                    type: 'order',
                                    paymentMode: 'stripe'
                                };
                                yield this.paymentChatMsgs(paymentChatMsg, order);
                                this.loading.dismiss();
                                this.presentAlert(this.labelService.labels['ORDER_SERVICE']['payment_is_successful']);
                            }
                            else {
                                this.loading.dismiss();
                                this.presentFailureAlert();
                            }
                        }));
                    }
                    else {
                        this.loading.dismiss();
                        this.presentFailureAlert();
                    }
                }));
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'order-service:payWithRazorPay';
                this.logglyService.log(error);
                this.loading.dismiss();
                this.presentFailureAlert();
            }
        });
    }
    ac_prepareOrderForPayment(order, paymentType) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.presentLoading();
                const orderId = this.afs.collection('orders').ref.doc().id;
                order['createdAt'] = new Date();
                const listOfCommentImages = Object.assign({}, order['listOfCommentImages']);
                delete order['listOfCommentImages'];
                if (order.uploadedDoc.uploads.length !== 0) {
                    const uploads = yield this.getUploadedDocUrls(orderId, order.uploadedDoc.uploads);
                    order.uploadedDoc['uploads'] = uploads;
                }
                yield this.afs.collection('orders').doc(orderId).set(order);
                order['orderDocId'] = orderId;
                this.addCommentImgs(listOfCommentImages, orderId);
                this.clearProductsInCartIfAny(order.userId);
                const payableAmnt = order.partialPayment.status ? order.partialPayment.online.amount : (order.totalAmountToPaid - (order.walletAmount + order.cashbackAmount));
                if (paymentType && (paymentType === 'paypal' || paymentType === 'cashfree')) {
                    this.loading.dismiss();
                }
                return { payableAmnt, order };
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'order-service:prepareOrderForPayment';
                this.logglyService.log(error);
            }
        });
    }
    ac_payWithPaypal(order, txnRes, payableAmnt) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.presentLoading();
                const paymentDetails = {
                    order: order,
                    mode: 'paypal',
                    txnRes: txnRes,
                    amount: payableAmnt
                };
                let ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                ac_saveOrderPaymentDetails(paymentDetails).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (res.data.status && res.data.status === 'success') {
                        this.loading.dismiss();
                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                    }
                    else {
                        yield this.paymentFailedUpdate(order.orderDocId);
                        this.loading.dismiss();
                        this.presentFailureAlert();
                    }
                }));
            }
            catch (error) {
                console.log(error);
            }
        });
    }
    payWithPaypal(order, txnRes, payableAmnt) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.presentLoading();
                const paymentDetails = {
                    order: order,
                    mode: 'paypal',
                    txnRes: txnRes,
                    amount: payableAmnt
                };
                let saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                saveOrderPaymentDetails(paymentDetails).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (res.data.status && res.data.status === 'success') {
                        const paymentChatMsg = {
                            author: 'user',
                            createdAt: new Date(),
                            isRead: true,
                            orderId: order.orderId,
                            published: true,
                            status: 'PaymentMsg',
                            type: 'order',
                            paymentMode: 'paypal'
                        };
                        yield this.paymentChatMsgs(paymentChatMsg, order);
                        this.loading.dismiss();
                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['payment_is_successful']);
                    }
                    else {
                        this.loading.dismiss();
                        this.presentFailureAlert();
                    }
                }));
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'order-service:payWithRazorPay';
                this.logglyService.log(error);
                this.loading.dismiss();
                this.presentFailureAlert();
            }
        });
    }
    ac_payWithCashfree(order, txnRes, payableAmnt) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                //await this.presentLoading();
                const paymentDetails = {
                    order: order,
                    mode: 'cashfree',
                    txnRes: txnRes,
                    amount: payableAmnt
                };
                console.log('paymentDetails:', paymentDetails);
                let ac_saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-ac_saveOrderPaymentDetails');
                ac_saveOrderPaymentDetails(paymentDetails).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (res.data.status && res.data.status === 'success') {
                        this.loading.dismiss();
                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['order_has_been_placed_successfully']);
                    }
                    else {
                        yield this.paymentFailedUpdate(order.orderDocId);
                        this.loading.dismiss();
                        this.presentFailureAlert();
                    }
                }));
            }
            catch (error) {
                console.log(error);
            }
        });
    }
    payWithCashfree(order, txnRes, payableAmnt) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                //await this.presentLoading();
                const paymentDetails = {
                    order: order,
                    mode: 'cashfree',
                    txnRes: txnRes,
                    amount: payableAmnt
                };
                console.log('paymentDetails from manual:', paymentDetails);
                let saveOrderPaymentDetails = firebase.functions().httpsCallable('payments-saveOrderPaymentDetails');
                saveOrderPaymentDetails(paymentDetails).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (res.data.status && res.data.status === 'success') {
                        const paymentChatMsg = {
                            author: 'user',
                            createdAt: new Date(),
                            isRead: true,
                            orderId: order.orderId,
                            published: true,
                            status: 'PaymentMsg',
                            type: 'order',
                            paymentMode: 'cashfree'
                        };
                        yield this.paymentChatMsgs(paymentChatMsg, order);
                        this.loading.dismiss();
                        this.presentAlert(this.labelService.labels['ORDER_SERVICE']['payment_is_successful']);
                    }
                    else {
                        this.loading.dismiss();
                        this.presentFailureAlert();
                    }
                }));
            }
            catch (error) {
                console.dir(error);
                error['location'] = 'order-service:payWithRazorPay';
                this.logglyService.log(error);
                this.loading.dismiss();
                this.presentFailureAlert();
            }
        });
    }
    createOrderCashfree(orderData, autoConfirm) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                try {
                    // await this.presentLoading();
                    let orderCashfree;
                    let payableAmount;
                    if (autoConfirm) {
                        const { payableAmnt, order } = yield this.ac_prepareOrderForPayment(orderData, 'cashfree');
                        payableAmount = payableAmnt;
                        console.log('order:', order);
                        orderCashfree = order;
                    }
                    else {
                        const payableAmnt = orderData.partialPayment.status ? orderData.partialPayment.online.amount : (orderData.totalAmountToPaid - (orderData.walletAmount + orderData.cashbackAmount));
                        payableAmount = payableAmnt;
                        orderCashfree = orderData;
                    }
                    const orderObj = {
                        orderAmnt: parseFloat(payableAmount.toFixed(2)),
                        userId: orderData.userId,
                        source: 'website-v2',
                        currencyCode: this.configService.environment.currencyCode,
                        user: {
                            id: orderData.userId,
                            email: this.userService.getUserEmail() || 'xyz@gmail.com',
                            phoneNo: this.userService.getPhoneNo() || ''
                        }
                    };
                    // let obj = {
                    //     cashfreeOrder: orderDataCashfree,
                    //     autoConfirm: autoConfirm,
                    //     payableAmount: payableAmount
                    // };
                    // await this.afs.collection('payment').doc('cashfree').collection('users').doc(orderObj.userId).set(obj);
                    let createOrderCashfree = firebase.functions().httpsCallable('payments-createOrderCashfree');
                    createOrderCashfree(orderObj).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                        if (res.data) {
                            const cashfreeToken = res.data.order_token;
                            // this.loading.dismiss();
                            resolve({ cashfreeToken, orderCashfree });
                        }
                        else {
                            // this.loading.dismiss();
                            this.presentFailureAlert();
                            resolve({});
                        }
                    })).catch((error) => {
                        // this.loading.dismiss();
                        this.presentFailureAlert();
                        resolve({});
                        console.log('err:::::::::::', error);
                    });
                }
                catch (error) {
                    // this.loading.dismiss();
                    this.presentFailureAlert();
                    resolve({});
                    console.log('err from catch:', error);
                }
            }));
        });
    }
    getOrderDetailsCashfree(cashfreeOrderId, orderData, autoConfirm) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.presentLoading();
                let obj = {
                    cashfreeOrderId: cashfreeOrderId
                };
                let getOrderDetailsCashfree = firebase.functions().httpsCallable('payments-getOrderDetailsCashfree');
                getOrderDetailsCashfree(obj).then((res) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    console.log('res:', res);
                    if (res.data) {
                        if (res.data.order_status == 'PAID') {
                            let txnRes = {
                                order_status: res.data.order_status,
                                cf_order_id: res.data.cf_order_id,
                                created_at: res.data.created_at,
                                order_id: res.data.order_id,
                                order_amount: res.data.order_amount,
                            };
                            if (autoConfirm) {
                                this.ac_payWithCashfree(orderData, txnRes, txnRes.order_amount);
                                console.log('successfully paid');
                            }
                            else {
                                this.payWithCashfree(orderData, txnRes, txnRes.order_amount);
                            }
                        }
                        else {
                            if (autoConfirm) {
                                yield this.paymentFailedUpdate(orderData.orderDocId);
                            }
                            else {
                                yield this.paymentFailedUpdate(orderData.id);
                            }
                            //console.log('inside failed with orderId:', cashfreeOrder);
                            //await this.paymentFailedUpdate(cashfreeOrder.orderDocId);
                            this.loading.dismiss();
                            this.presentFailureAlert();
                            console.log('not paid');
                        }
                    }
                    else {
                        yield this.paymentFailedUpdate(orderData.orderDocId);
                        this.loading.dismiss();
                        this.presentFailureAlert();
                    }
                })).catch((error) => {
                    console.log('err:::::::::::', error);
                });
            }
            catch (error) {
                console.log('error:', error);
            }
        });
    }
    cashfreePaymentFailed(autoConfirm, orderData) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (autoConfirm) {
                yield this.paymentFailedUpdate(orderData.orderDocId);
            }
            else {
                yield this.paymentFailedUpdate(orderData.id);
            }
        });
    }
}
OrderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OrderService_Factory() { return new OrderService(i0.ɵɵinject(i1.Events), i0.ɵɵinject(i2.Storage), i0.ɵɵinject(i3.AngularFirestore), i0.ɵɵinject(i4.UserService), i0.ɵɵinject(i1.NavController), i0.ɵɵinject(i5.Router), i0.ɵɵinject(i1.ModalController), i0.ɵɵinject(i1.LoadingController), i0.ɵɵinject(i1.AlertController), i0.ɵɵinject(i6.ConfigService), i0.ɵɵinject(i7.LogglyLoggerService), i0.ɵɵinject(i8.LabelService), i0.ɵɵinject(i9.AngularFireStorage), i0.ɵɵinject(i10.CartService), i0.ɵɵinject(i9.AngularFireStorage)); }, token: OrderService, providedIn: "root" });
